<template>
  <b-container fluid>
    <b-row class="mb-4" v-if="!firstRun">
      <b-col>
        <b-input-group class="my-2">
          <b-form-input class="mr-2" v-model="like" placeholder="Search url" />
          <b-btn class="mr-2" @click="load">search</b-btn>
          <b-btn :disabled="!like" @click="showAll">show all</b-btn>
        </b-input-group>
        <b-pagination
          v-model="page"
          :limit=10
          :total-rows="totalRows"
          :per-page="pageSize"
        />
        Total link: {{totalRows}}
        <span v-if="!loading">
          <div class="mb-2" v-for="link in links" :key="link.id">
            <link-item :link="link" />
          </div>
        </span>
        <span v-show="loading">
          <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
        </span>
        <b-pagination
          v-model="page"
          :limit=10
          :total-rows="totalRows"
          :per-page="pageSize"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import LinkItem from '@/components/Link.vue'

export default {
  name: 'Links',
  components: {
    LinkItem
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "Links"
    if (!ac.can(this.user.acgroups).readAny('rricpanel').granted) {
      this.$router.push({ name: 'Start' })
    }
    this.$stat.log({ page: 'admin links', action: 'open page' })
    this.load()
  },
  data () {
    return {
      firstRun: true,
      loading: false,
      like: null,
      links: [],
      totalRows: 0,
      pageSize: 200,
      page: 1
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('loading started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path
        if (this.like) {
          path = `/links/page/${this.page}/limit/${this.pageSize}/like/${this.like}`
        } else {
          path = `/links/page/${this.page}/limit/${this.pageSize}`
        }
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug(response)
        this.links = response.results
        this.totalRows = response.total
        this.$logger.debug(this.news)
        this.firstRun = false
        this.loading = false
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
    },
    reload: function () {
      this.page = 1
      this.totalRows = 0
      this.collapseVisible = false
      this.load()
    },
    showAll: function () {
      this.like = null
      this.load()
    }
  },
  watch: {
    page: 'load'
  }
}
</script>

<style>
</style>
